import React from 'react';
import { Container } from "reactstrap";

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <section className="bg-home4 overflow-hidden" id="home">
                <Container>

                    <div style={styles.container}>
                        <h1 style={styles.heading1}>Kebijakan Privasi Simpede</h1>

                        <p>Tanggal Efektif: 21 Oktober 2024</p>

                        <p>
                            Simpede menghormati privasi Anda dan berkomitmen untuk melindungi informasi pribadi yang Anda berikan saat menggunakan aplikasi kami. Kebijakan ini menjelaskan jenis informasi yang kami kumpulkan, cara kami menggunakan informasi tersebut, dan langkah-langkah yang kami ambil untuk melindungi informasi Anda.
                        </p>

                        <h2 style={styles.heading2}>Informasi yang Kami Kumpulkan</h2>
                        <ul>
                            <li>
                                <strong>Informasi Pribadi:</strong> Nama, alamat email, nomor telepon, dan informasi lain yang Anda berikan saat mendaftar atau menggunakan aplikasi.
                            </li>
                            <li>
                                <strong>Data Akademik:</strong> Nilai, absensi, dan informasi akademik lainnya yang terkait dengan penggunaan aplikasi.
                            </li>
                            <li>
                                <strong>Data Penggunaan:</strong> Informasi tentang bagaimana Anda menggunakan aplikasi, termasuk waktu akses, durasi penggunaan, dan fitur yang digunakan.
                            </li>
                        </ul>

                        <h2 style={styles.heading2}>Penggunaan Informasi</h2>
                        <p>Kami menggunakan informasi yang kami kumpulkan untuk:</p>
                        <ul>
                            <li>Mengelola akun pengguna dan memberikan layanan yang Anda minta.</li>
                            <li>Meningkatkan aplikasi dan pengalaman pengguna.</li>
                            <li>Memberikan informasi dan pembaruan terkait aplikasi.</li>
                            <li>Menganalisis penggunaan aplikasi untuk mengembangkan fitur baru.</li>
                        </ul>

                        <h2 style={styles.heading2}>Perlindungan Informasi</h2>
                        <p>
                            Kami mengambil langkah-langkah yang sesuai untuk melindungi informasi pribadi Anda dari akses yang tidak sah, penggunaan, atau pengungkapan. Kami menggunakan teknologi enkripsi dan praktik keamanan yang ketat untuk menjaga informasi Anda aman.
                        </p>

                        <h2 style={styles.heading2}>Pengungkapan kepada Pihak Ketiga</h2>
                        <p>
                            Kami tidak menjual atau menyewakan informasi pribadi Anda kepada pihak ketiga. Informasi Anda mungkin dibagikan dengan penyedia layanan yang membantu kami menjalankan aplikasi, tetapi hanya untuk tujuan yang dinyatakan dalam kebijakan ini.
                        </p>

                        <h2 style={styles.heading2}>Hak Anda</h2>
                        <p>
                            Anda memiliki hak untuk mengakses, memperbaiki, atau menghapus informasi pribadi Anda yang kami simpan. Jika Anda ingin melaksanakan hak ini, silakan hubungi kami melalui informasi kontak di bawah ini.
                        </p>

                        <h2 style={styles.heading2}>Perubahan Kebijakan Privasi</h2>
                        <p>
                            Kami dapat memperbarui kebijakan privasi ini dari waktu ke waktu. Kami akan memberi tahu Anda tentang perubahan dengan memposting kebijakan privasi yang diperbarui di dalam aplikasi atau melalui email.
                        </p>

                        <h2 style={styles.heading2}>Hubungi Kami</h2>
                        <p>
                            Jika Anda memiliki pertanyaan atau kekhawatiran tentang kebijakan privasi ini, silakan hubungi kami di:
                        </p>
                        <p>Email: support@simpede.com</p>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        color: '#333',
    },
};

export default PrivacyPolicy;
