import React from "react";

import BackToTop from "../components/BackToTop";
import Contact from "../components/Contact";
import Footer from "../Layout/Footer";
import FooterAlt from "../Layout/FooterAlt";
import Pricing from "../components/Pricing";
import Services from "../components/Services";

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <Services />
      <Pricing />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout;
