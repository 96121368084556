import React from "react";

import BackToTop from "../components/BackToTop";

const BlankLayout = (props) => {
    return (
        <React.Fragment>
            {props.children}
            <BackToTop />
        </React.Fragment>
    );
};

export default BlankLayout;
