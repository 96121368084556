import React, { useState, useEffect } from 'react'
import NavBar from '../../Layout/Navbar'
import { Button, Container } from 'reactstrap'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PPDB_ENDPOINTS } from '../../config';

const Detail = () => {
    const notify = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            case 'warning':
                toast.warning(message);
                break;
            default:
                toast.info(message);
        }
    };

    useEffect(() => {
        // Dynamically load the Midtrans Snap script
        const script = document.createElement('script');
        // script.src = "https://app.stg.midtrans.com/snap/snap.js";
        script.src = "https://app.midtrans.com/snap/snap.js";
        script.setAttribute('data-client-key', process.env.REACT_APP_MIDTRANS_CLIENT_KEY); // Replace with your client key
        script.async = true;
        script.onload = () => {
            console.log('Midtrans Snap script loaded successfully');
        };
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePayment = () => {
        window.open(transaction.pg_response.redirect_url, '_self')

        // if (window.snap) {
        //     // Trigger snap popup. Replace 'YOUR_SNAP_TOKEN' with your transaction token.
        //     window.snap.pay('729b96eb-0fb9-43ed-aa17-d4b6a5b99232', {
        //         onSuccess: function (result) {
        //             alert("Payment success!");
        //             console.log(result);
        //         },
        //         onPending: function (result) {
        //             alert("Waiting for your payment!");
        //             console.log(result);
        //         },
        //         onError: function (result) {
        //             alert("Payment failed!");
        //             console.log(result);
        //         },
        //         onClose: function () {
        //             alert('You closed the popup without finishing the payment');
        //         }
        //     });
        // } else {
        //     console.error('Snap is not loaded yet.');
        // }
    };

    const [appCheckToken, setAppCheckToken] = useState(localStorage.getItem('appCheckToken'));
    const [isHide, setIsHide] = React.useState(false);
    const [transaction, setTransaction] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const queryParams = new URLSearchParams(window.location.search);

    const orderId = queryParams.get('order_id');

    const fetchDataTransaction = async () => {
        setLoading(true);
        try {
            const response = await fetch(PPDB_ENDPOINTS.DETAILS + '?transaction_id=' + orderId, {
                method: 'GET',
                headers: {
                    'X-Firebase-AppCheck': appCheckToken,
                },
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to fetch data');
            }

            if (data.status === 'pending') {
                setIsHide(false);
            } else {
                setIsHide(true);
            }

            setTransaction(data);
        } catch (err) {
            setError(err.message);
            notify(err.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setAppCheckToken(localStorage.getItem('appCheckToken'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (appCheckToken) {
            fetchDataTransaction();
        }
    }, [appCheckToken]);

    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <NavBar isDark={false} />
            <div className="pt-5 mt-5">
                <Container>

                    {transaction && transaction.status === 'settlement' ? (
                        <div className="card border-3 rounded-0 border-success">
                            <div className="card-body text-center">
                                <p className="text-dark fw-semibold lead mb-0">
                                    <i className="fas fa-check text-success me-1" /> Pembayaran telah kami terima. Terima kasih.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="card border-3 rounded-0 border-warning">
                            <div className="card-body text-center">
                                <p className="text-dark fw-semibold lead mb-0">
                                    <i className="fas fa-clock text-warning me-1" /> Menunggu pembayaran
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="d-flex flex-column flex-md-row justify-content-between py-3 px-4 my-4">
                        <div className="text-center">
                            <span>
                                ID Transaksi <br />
                                <strong className="text-color-dark">{transaction ? transaction.id : ''}</strong>
                            </span>
                        </div>
                        <div className="text-center mt-4 mt-md-0">
                            <span>
                                Tanggal <br />
                                <strong className="text-color-dark">{transaction ? new Date(transaction.created_on).toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}</strong>
                            </span>
                        </div>
                        <div className="text-center mt-4 mt-md-0">
                            <span>
                                Nomor Ponsel <br />
                                <strong className="text-color-dark">{transaction ? transaction.student.user.phone_number : ''}</strong>
                            </span>
                        </div>
                        <div className="text-center mt-4 mt-md-0">
                            <span>
                                Total <br />
                                <strong className="text-color-dark">Rp {transaction ? (transaction.amount + 2000).toLocaleString('id-ID') : ''}</strong>
                            </span>
                        </div>
                    </div>
                    <div className="card border-3 rounded-0 mb-1">
                        <div className="card-body pt-3 pt-md-5 px-3 px-md-5">
                            <h4 className="fw-bold text-uppercase text-4 mb-3">Identitas Siswa</h4>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td className='py-3'>
                                            <strong className="d-block text-color-dark line-height-1 font-weight-semibold">Nama Lengkap</strong>
                                            <span className="text-1">{`${transaction ? transaction.student.user.first_name : ''} ${transaction ? transaction.student.user.last_name || "" : ""}`}</span>
                                        </td>
                                        <td className="border-top-0 text-end">
                                            <strong><span className="amount font-weight-medium"></span></strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3">
                                            <strong className="d-block text-color-dark line-height-1 font-weight-semibold">Jenis Kelamin</strong>
                                            <span className="text-1">{transaction ? transaction.student.jenis_kelamin : ''}</span>
                                        </td>
                                        <td className="border-top-0 text-end">
                                            <strong><span className="amount font-weight-medium"></span></strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3">
                                            <strong className="d-block text-color-dark line-height-1 font-weight-semibold">NIK</strong>
                                            <span className="text-1">{transaction ? transaction.student.nik : ''}</span>
                                        </td>
                                        <td className="border-top-0 text-end">
                                            <strong><span className="amount font-weight-medium"></span></strong>
                                        </td>
                                    </tr>
                                    <tr className="cart-subtotal">
                                        <td className="border-top-0 py-3">
                                            <strong className="text-color-dark">Subtotal</strong>
                                        </td>
                                        <td className="border-top-0 text-end">
                                            <strong><span className="amount font-weight-medium">Rp {transaction ? transaction.amount.toLocaleString('id-ID') : ''}</span></strong>
                                        </td>
                                    </tr>
                                    <tr className="shipping">
                                        <td className="border-top-0 py-3">
                                            <strong className="text-color-dark">Biaya Admin</strong>
                                        </td>
                                        <td className="border-top-0 text-end">
                                            <strong><span className="amount font-weight-medium">Rp 2.000</span></strong>
                                        </td>
                                    </tr>
                                    <tr className="total">
                                        <td className="py-3">
                                            <strong className="text-color-dark text-3-5">Total</strong>
                                        </td>
                                        <td className="text-end py-3">
                                            <strong className="text-color-dark"><span className="amount text-color-dark text-5">Rp {transaction ? (transaction.amount + 2000).toLocaleString('id-ID') : ''}</span></strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {!isHide && <div className="px-5 pb-4">
                            <Button color='primary' onClick={handlePayment} className='d-flex align-items-center justify-content-center text-center w-100'>Bayar Sekarang</Button>
                        </div>}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Detail