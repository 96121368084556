import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";
import img04 from "../assets/images/feature/img-04.png";
import img05 from "../assets/images/feature/img-05.png";
import img06 from "../assets/images/feature/img-06.png";

const ServicesCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          <img src={props.imgsrc} alt="" className="img-fluid" />
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Services data

const ServicesData = [
  {
    imgsrc: img01,
    title: "PPDB Online",
    caption:
      "Mempermudah proses penerimaan peserta didik baru secara daring dengan fitur yang lengkap dan user-friendly.",
  },
  {
    imgsrc: img02,
    title: "Manajemen Siswa dan Guru",
    caption:
      "Memberikan kemudahan dalam mengelola data dan aktivitas guru serta siswa di lingkungan pendidikan.",
    isShadow: true,
  },
  {
    imgsrc: img03,
    title: "Integrasi Pembayaran",
    caption:
      "Mengelola transaksi keuangan secara lebih mudah dengan mengintegrasikan berbagai metode pembayaran.",
  },
  {
    imgsrc: img04,
    title: "Laporan Akademik",
    caption:
      "Membantu dalam menyajikan laporan hasil akademik siswa secara cepat, transparan, dan terstruktur.",
    isShadow: true,
  },
  {
    imgsrc: img05,
    title: "Komunikasi Terpadu",
    caption:
      "Memfasilitasi komunikasi yang efektif dan efisien antara pihak sekolah, guru, siswa, dan orang tua.",
  },
  {
    imgsrc: img06,
    title: "Backup Data",
    caption:
      "Mengamankan serta melindungi data penting anda secara otomatis dan berkala dari resiko kehilangan.",
    isShadow: true,
  },
];

const Services = () => {
  return (
    <>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  Layanan yang Kami Berikan
                </p>
                <h3>Berbagai Fitur dan Keunggulan</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                  Kami menyediakan layanan sebagai solusi untuk memenuhi kebutuhan digital Anda,
                  mulai dari penyimpanan data, pengelolaan infrastruktur IT, hingga keamanan siber.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Services Component Start */}

            {ServicesData.map((value, idx) => (
              <ServicesCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Services Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Services;
