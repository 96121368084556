import React, { useState, useEffect } from 'react'
import { Container } from 'reactstrap'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logoDark from "../../assets/images/logo.png"

import { PPDB_ENDPOINTS, SCHOOL_ENDPOINTS } from '../../config';

const Detail = () => {

  const notify = (message, type) => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'warning':
        toast.warning(message);
        break;
      default:
        toast.info(message);
    }
  };

  const [school, setSchool] = useState({});

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    jenis_kelamin: '',
    nik: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    agama: '',
    alamat: '',
    kode_pos: '',
    nama_ayah: '',
    pekerjaan_ayah: '',
    pendidikan_ayah: '',
    penghasilan_ayah: '',
    nama_ibu: '',
    pekerjaan_ibu: '',
    pendidikan_ibu: '',
    penghasilan_ibu: '',
    // schoolId: '',
  });
  const [appCheckToken, setAppCheckToken] = useState(localStorage.getItem('appCheckToken'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(PPDB_ENDPOINTS.REGISTER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Firebase-AppCheck': appCheckToken,
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit form');
      }

      console.log(data);
      notify('Berhasil mendaftar', 'success');
      window.location.href = '/ppdb/details?order_id=' + data.transaction_id;

    } catch (err) {
      setError(err.message);
      notify(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  // const fetchDataSchool = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(SCHOOL_ENDPOINTS.GET_DATA, {
  //       method: 'GET',
  //       headers: {
  //         'X-Firebase-AppCheck': appCheckToken,
  //       },
  //     });
  //     const data = await response.json();

  //     if (!response.ok) {
  //       throw new Error(data.message || 'Failed to fetch data');
  //     }

  //     setSchool(data.data);
  //   } catch (err) {
  //     setError(err.message);
  //     notify(err.message, 'error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const handleStorageChange = () => {
      setAppCheckToken(localStorage.getItem('appCheckToken'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // useEffect(() => {
  //   if (appCheckToken) {
  //     fetchDataSchool();
  //   }
  // }, [appCheckToken]);

  return (
    <React.Fragment>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="subscribe-form box-shadow mt-4 mt-lg-0">
            <div className="text-center mb-4 pb-2">
              <img
                src={logoDark}
                className="logo-light mb-2"
                alt=""
                height="48"
              />
              <p className="text-muted text-uppercase mb-2">
                Selamat datang
              </p>
              <h5>Silakan lengkapi data di bawah ini !</h5>
            </div>
            <form action="#">
              {/* <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Sekolah*
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="schoolId"
                  value={formData.schoolId}

                >
                  <option value="">Pilih Sekolah</option>
                  {Array.isArray(school) && school.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.school_name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Nama Depan*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Masukkan nama depan"
                  onChange={handleInputChange}
                  name="first_name"
                  value={formData.first_name}

                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Nama Belakang
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Masukkan nama belakang"
                  onChange={handleInputChange}
                  name="last_name"
                  value={formData.last_name}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput2"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput2"
                  placeholder="Masukkan email"
                  onChange={handleInputChange}
                  name="email"
                  value={formData.email}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Nomor Ponsel*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan nomor ponsel"
                  onChange={handleInputChange}
                  name="phone_number"
                  value={formData.phone_number}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Jenis Kelamin*
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="jenis_kelamin"
                  value={formData.jenis_kelamin}

                >
                  <option value="">Pilih Jenis Kelamin</option>
                  <option value="Laki-laki">Laki-laki</option>
                  <option value="Perempuan">Perempuan</option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  NIK*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan NIK"
                  onChange={handleInputChange}
                  name="nik"
                  value={formData.nik}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Tempat Lahir*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan tempat lahir"
                  onChange={handleInputChange}
                  name="tempat_lahir"
                  value={formData.tempat_lahir}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Tanggal Lahir*
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleFormControlInput3"
                  onChange={handleInputChange}
                  name="tanggal_lahir"
                  value={formData.tanggal_lahir} />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Agama*
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="agama"
                  value={formData.agama}
                >
                  <option value="">Pilih Agama</option>
                  <option value="Islam">Islam</option>
                  <option value="Kristen">Kristen</option>
                  <option value="Katolik">Katolik</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Budha">Budha</option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Alamat*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan alamat"
                  onChange={handleInputChange}
                  name="alamat"
                  value={formData.alamat}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Kode Pos
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan kode pos"
                  onChange={handleInputChange}
                  name="kode_pos"
                  value={formData.kode_pos}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Nama Ayah
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan nama ayah"
                  onChange={handleInputChange}
                  name="nama_ayah"
                  value={formData.nama_ayah}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Pekerjaan Ayah
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="pekerjaan_ayah"
                  value={formData.pekerjaan_ayah}
                >
                  <option value="">Pilih Pekerjaan</option>
                  <option value="Belum/Tidak Bekerja">Belum/Tidak Bekerja</option>
                  <option value="Mengurus Rumah Tangga">Mengurus Rumah Tangga</option>
                  <option value="PNS">PNS</option>
                  <option value="Pensiunan">Pensiunan</option>
                  <option value="TNI">TNI</option>
                  <option value="Polri">Polri</option>
                  <option value="Wiraswasta">Wiraswasta</option>
                  <option value="Petani">Petani</option>
                  <option value="Nelayan">Nelayan</option>
                  <option value="Buruh">Buruh</option>
                  <option value="Guru">Guru</option>
                  <option value="Karyawan Swasta">Karyawan Swasta</option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Pendidikan Ayah
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="pendidikan_ayah"
                  value={formData.pendidikan_ayah}
                >
                  <option value="">Pilih Pendidikan</option>
                  <option value="SD">SD</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/Sederajat">SMA/Sederajat</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Penghasilan Ayah
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="penghasilan_ayah"
                  value={formData.penghasilan_ayah}
                >
                  <option value="">Pilih Penghasilan</option>
                  <option value="0 - 1.000.000">0 - 1.000.000</option>
                  <option value="1.000.000 - 2.000.000">1.000.000 - 2.000.000</option>
                  <option value="2.000.000 - 3.000.000">2.000.000 - 3.000.000</option>
                  <option value="3.000.000 - 4.000.000">3.000.000 - 4.000.000</option>
                  <option value="> 4.000.000"> &gt; 4.000.000 </option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Nama Ibu
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="Masukkan nama ibu"
                  onChange={handleInputChange}
                  name="nama_ibu"
                  value={formData.nama_ibu}
                />
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Pekerjaan Ibu
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="pekerjaan_ibu"
                  value={formData.pekerjaan_ibu}
                >
                  <option value="">Pilih Pekerjaan</option>
                  <option value="Belum/Tidak Bekerja">Belum/Tidak Bekerja</option>
                  <option value="Mengurus Rumah Tangga">Mengurus Rumah Tangga</option>
                  <option value="PNS">PNS</option>
                  <option value="Pensiunan">Pensiunan</option>
                  <option value="TNI">TNI</option>
                  <option value="Polri">Polri</option>
                  <option value="Wiraswasta">Wiraswasta</option>
                  <option value="Petani">Petani</option>
                  <option value="Nelayan">Nelayan</option>
                  <option value="Buruh">Buruh</option>
                  <option value="Guru">Guru</option>
                  <option value="Karyawan Swasta">Karyawan Swasta</option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Pendidikan Ibu
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="pendidikan_ibu"
                  value={formData.pendidikan_ibu}
                >
                  <option value="">Pilih Pendidikan</option>
                  <option value="SD">SD</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/Sederajat">SMA/Sederajat</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </select>
              </div>
              <div className="mb-4 position-relative">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label"
                >
                  Penghasilan Ibu
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name="penghasilan_ibu"
                  value={formData.penghasilan_ibu}
                >
                  <option value="">Pilih Penghasilan</option>
                  <option value="0 - 1.000.000">0 - 1.000.000</option>
                  <option value="1.000.000 - 2.000.000">1.000.000 - 2.000.000</option>
                  <option value="2.000.000 - 3.000.000">2.000.000 - 3.000.000</option>
                  <option value="3.000.000 - 4.000.000">3.000.000 - 4.000.000</option>
                  <option value="> 4.000.000"> &gt; 4.000.000 </option>
                </select>
              </div>

              <div className="mb-4">
                <p className="text-muted">
                  <small>* Minimal biaya pendaftaran : 50% dari biaya keseluruhan</small>
                </p>
              </div>


              <div className="pt-2">
                <button disabled={loading} type="submit" className="btn btn-primary w-100" onClick={handleSubmit}>
                  {loading ? 'Loading...' : 'Daftar Sekarang'}
                </button>
              </div>
            </form>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Detail