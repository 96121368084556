import Layout4 from "../pages/Layout4"
import PrivacyPolicy from "../pages/PrivacyPolicy";
import PPDB from "../pages/Form/Form";
import Detail from "../pages/Payment/Detail";


const blankRoutes = [
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/ppdb", component: <PPDB /> },
  { path: "/ppdb/details", component: <Detail /> },
];

const defaultRoutes = [
  { path: "/", component: <Layout4 /> },
];

export { blankRoutes, defaultRoutes };